html {
  height: 100%;
}

#root {
  height: 100%;
}

.app-frame {
  height: auto;
  display: flex;
  flex-direction: column;
}

body {
  height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.navbar {
  background-color: #333;
  position: fixed; /* Set the navbar to fixed position */
  top: 0; /* Position the navbar at the top of the page */
  width: 100%; /* Full width */
  z-index: 1;
}

/* Links inside the navbar */
.navbar a {
  float: left;
  display: block;
  color: #f2f2f2;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

/* The dropdown container */
.dropdown {
  float: left;
  overflow: hidden;
}

/* Dropdown button */
.dropdown .dropbtn {
  font-size: 16px;
  border: none;
  outline: none;
  color: white;
  padding: 14px 16px;
  background-color: inherit;
  font-family: inherit; /* Important for vertical align on mobile phones */
  margin: 0; /* Important for vertical align on mobile phones */
}

/* Add a red background color to navbar links on hover */
.navbar a:hover,
.dropdown:hover .dropbtn {
  background: #ddd;
  color: black;
}

.navbar .navTitle {
  background-color: #111;
  color: #f2f2f2;
}

.navbar .navTitle:hover {
  background-color: #111;
  color: #f2f2f2;
}

/* Dropdown content (hidden by default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  float: none;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

/* Add a grey background color to dropdown links on hover */
.dropdown-content a:hover {
  background-color: #ddd;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

/* Main content */
.main {
  margin-top: 50px; /* Add a top margin to avoid content overlay */
  flex: 1;
}

/* The button used to open the sidebar */
.selectUserButton {
  font-size: 20px;
  cursor: pointer;
  background-color: #111;
  color: white;
  padding: 10px 15px;
  border: none;
}

.selectUserButton:hover {
  background-color: #444;
}

/* The button used to open the sidebar */
.goButton {
  font-size: 20px;
  cursor: pointer;
  background-color: rgb(0, 150, 20);
  color: white;
  padding: 10px 15px;
  border: none;
  width: 200px;
  margin: auto;
}

.goButton.wide {
  width: 50%;
}

.stopButton {
  font-size: 20px;
  cursor: pointer;
  background-color: rgb(190, 25, 25);
  color: white;
  padding: 10px 15px;
  border: none;
  width: 200px;
  margin: auto;
}

.stopButton.wide {
  width: 50%;
}

.userList {
  height: 95%; /* Specify a height */
  width: 0; /* 0 width - change this with JavaScript */
  position: fixed; /* Stay in place */
  z-index: 1; /* Stay on top */
  top: 0;
  left: 0;
  background-color: #111; /* Black*/
  overflow-x: hidden; /* Disable horizontal scroll */
  transition: 0.5s; /* 0.5 second transition effect to slide in the sidepanel */
  margin-top: 49px;
}

/* Position and style the close button (top right corner) */
.userList .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

.userList h2 {
  text-decoration: none;
  font-size: 25px;
  color: #818181;
}

/* The sidebar links */
.userList a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

/* When you mouse over the navigation links, change their color */
.userList a:hover {
  color: #f1f1f1;
}

#userContainer {
  padding-left: 10px;
}

#userManager
{
  display: flex;
}

#userManager .closebtn {
  position: relative;
  top: 50px;
  left: 100%;
  font-size: 36px;
}

.transactionBlock {
  height: 100%;
  min-height: 575px;
}

#aboutOverlay {
  position: fixed; /* Sit on top of the page content */
  display: block; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
}

.aboutOverlay {
  width: 80%;
  max-width: 950px;
  margin: 5px;
  padding: 5px;
  position: relative;
  top: 25%;
  background-color: #fff;
  overflow: auto;
  left: 50%;
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  overflow-y: auto;
  max-height: 400px;
}

.aboutOverlay h1 {
  text-align: center;
  color: #000;
  margin: 0;
}
.aboutOverlay h4 {
  text-align: center;
  color: #000;
}

.centerOverlay {
  width: 80%;
  margin: 5px;
  padding: 5px;
  position: relative;
  top: 100px;
  background-color: #000;
  overflow: auto;
  left: 50%;
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

@media screen and (max-width: 750px) {
  .centerOverlay {
    width: 100%;
    padding: 5px;
    position: relative;
    top: 75px;
    background-color: #000;
    overflow: auto;
    left: 50%;
  }

  .aboutOverlay {
    width: 95%;
    max-height: 600px;
    margin: 0px;
    padding: 0px;
    position: relative;
    top: 75px;
    overflow: auto;
    left: 50%;
  }
}

.centerOverlay h1 {
  text-align: center;
  color: #dfeeff;
  margin: 0;
}
.centerOverlay h4 {
  text-align: center;
  color: #dfeeff;
  margin-bottom: 0px;
}

#overlay {
  position: fixed; /* Sit on top of the page content */
  display: block; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
}

.logTitleBar {
  text-align: center;
  margin-top: 0px;
  margin-bottom: 0px;
  border-style: solid;
  background-color: #2b408f;
}
.logTitleBar h4 {
  text-align: center;
  margin-top: 0px;
  margin-bottom: 0px;
  color: #dfeeff;
  font-family: 'Courier New', Courier, monospace;
}

.logField {
  min-height: 150px;
  max-height: 150px;
  overflow: scroll;
  background-color: #000;
}

.logField p {
  margin: 0px;
  margin-left: 10px;
  color: #dfeeff;
  font-family: 'Courier New', Courier, monospace;
}

.gun {
  position: relative;
  width: 225px;
  height: 75px;
  margin-bottom: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: #888;
}

.gun img {
  width: 75px;
  float: left;
  border-radius: 5px;
  margin-right: 10px;
}

.gun h2 {
  font-family: courier;
  color: #333;
  margin: 0 auto;
  padding: 0;
  font-size: 15pt;
}

.gun p {
  font-family: courier;
  color: #555;
  font-size: 12px;
}

.gun span {
  font-family: courier;
}
