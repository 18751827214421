
.left {
    left: 25px;
  }
  
  .right {
    right: 25px;
  }
  
  .center {
    text-align: center;
  }
  
  .bottom {
    position: absolute;
    bottom: 25px;
  }
  
  
  .UserListEntry {
    position: relative;
    width: 225px;
    height: 75px;
    margin-bottom: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background-color: oldlace;
  }
  
  .UserListEntry img {
    width: 75px;
    float: left;
    border-radius: 5px;
    margin-right: 10px;
  }
  
  .UserListEntry h2 {
    font-family: courier;
    color: #333;
    margin: 0 auto;
    padding: 0;
    font-size: 15pt;
  }
  
  .UserListEntry p {
    font-family: courier;
    color: #555;
    font-size: 12px;
  }
  
  .UserListEntry span {
    font-family: courier;
  }