
.left {
    left: 25px;
  }
  
  .right {
    right: 25px;
  }
  
  .center {
    text-align: center;
  }
  
  .bottom {
    position: absolute;
    bottom: 25px;
  }
  
  
  .IDCard {
    position: relative;
    width: 300px;
    height: 150px;
    padding: 25px;
    padding-top: 5;
    padding-bottom: 5;
    margin-bottom: 10px;
    margin-left:auto;
    margin-right: auto;
    background: #E9E2D0;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  
  .IDCard img {
    width: 150px;
    float: left;
    border-radius: 5px;
    margin-right: 20px;
  }
  
  .IDCard h2 {
    font-family: courier;
    color: #333;
    margin: 0 auto;
    padding: 0;
    font-size: 15pt;
  }
  
  .IDCard p {
    font-family: courier;
    color: #555;
    font-size: 13px;
  }
  
  .IDCard span {
    font-family: courier;
  }