.row {
  width: 75%;
  margin: 0;
  position: relative;
  left: 50%;
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.row50 {
  margin: 0;
  width: 47%;
  position: relative;
  left: 50%;
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.column50 {
  width: 50%;
  height: max-content;
}

.rightColumn {
  float: right;
}

.leftColumn {
  float: left;
}

.centerText {
  text-align: center;
}

.container {
  position: relative;
  flex: 1 1 200px;
  margin: auto;
}

.centerHorizontal {
  display: flex;
  flex-direction: column;
  margin: auto;
}

.centerVertical {
  margin: 0;
  position: absolute;
  top: 50%;
}

.transactionUI {
  display: flex;
  flex-direction: column;
}

.transactionUI.container p {
  text-align: center;
}

.Transaction {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.serverLogs {
  flex: 1 1 auto;
}

.idRow {
  display: flex;
  flex-direction: column;
}

.idLabel {
  flex: 1;
}

.idImage {
  flex: 1;
  display: flex;
}

/* Clear floats after the columns */
.Transaction:after {
  content: '';
  display: table;
  clear: both;
}
